import Cookies from "js-cookie";
import NextCookies from "next-cookies";
const ACTIVE_TOKEN_NAME = "tapforservice_active_token";
const ALL_TOKENS_NAME = "tapforservice_all_token";
const MAX_AGE = 60 * 60 * 24 * 7; // 7 days

const createTokenCookies = (tokens) => {
  let expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 30 * 86400 * 1000);

  document.cookie =
    ALL_TOKENS_NAME +
    "=" +
    JSON.stringify(tokens) +
    "; expires=" +
    expirationDate.toUTCString() +
    "; path=/;";

  document.cookie =
    ACTIVE_TOKEN_NAME +
    "=" +
    JSON.stringify(tokens[0]) +
    "; expires=" +
    expirationDate.toUTCString() +
    "; path=/;";
};

const removeTokenCookies = () => {
  Cookies.remove(ACTIVE_TOKEN_NAME);
  Cookies.remove(ALL_TOKENS_NAME);
};

function getActiveAuthToken(ctx) {
  if (ctx) {
    const allCookies = NextCookies(ctx);
    return allCookies[ACTIVE_TOKEN_NAME];
  } else {
    if (Cookies.get(ACTIVE_TOKEN_NAME)) {
      return JSON.parse(Cookies.get(ACTIVE_TOKEN_NAME));
    } else {
      return null;
    }
  }
}

function getAllAuthToken() {
  return JSON.parse(Cookies.get(ALL_TOKENS_NAME));
}

function getSiteId() {
  const activeToken = Cookies.get(ACTIVE_TOKEN_NAME);
  if (activeToken) {
    return JSON.parse(activeToken).site_id;
  } else {
    return null;
  }
}

function getCurrentUserId() {
  const activeToken = Cookies.get(ACTIVE_TOKEN_NAME);
  if (activeToken) {
    return JSON.parse(activeToken).user_id;
  } else {
    return null;
  }
}

function getCurrentUser() {
  const activeToken = Cookies.get(ACTIVE_TOKEN_NAME);
  if (activeToken) {
    return JSON.parse(activeToken);
  } else {
    return null;
  }
}

function setActiveToken(selectedToken) {
  const allTokens = JSON.parse(Cookies.get(ALL_TOKENS_NAME));
  const fullSelectedToken = allTokens.find(
    (token) => token.token === selectedToken
  );
  let expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 30 * 86400 * 1000);

  document.cookie =
    ACTIVE_TOKEN_NAME +
    "=" +
    JSON.stringify(fullSelectedToken) +
    "; expires=" +
    expirationDate.toUTCString() +
    "; path=/;";
}

export {
  createTokenCookies,
  removeTokenCookies,
  getActiveAuthToken,
  getAllAuthToken,
  setActiveToken,
  getSiteId,
  getCurrentUserId,
  getCurrentUser,
  ACTIVE_TOKEN_NAME,
  ALL_TOKENS_NAME,
};
