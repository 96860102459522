import NProgress from "nprogress"; //nprogress module
import Router from "next/router";
import { useAnalytics } from "../../../contexts/Segment/Segment";
import { useEffect } from "react";

const RouterListener = ({ children }) => {
  const analytics = useAnalytics();

  useEffect(() => {
    // initial page load
    analytics.page();

    Router.events.on("routeChangeStart", () => NProgress.start());
    Router.events.on("routeChangeComplete", () => {
      // client side SPA page load
      analytics.page();
      NProgress.done();
    });
    Router.events.on("routeChangeError", () => NProgress.done());
  }, []);

  return <>{children}</>;
};

export default RouterListener;
