import React from "react";

// tailwind styles
import "../styles/main.css";

//Mapbox controls styling - not needed yet
// import "mapbox-gl/dist/mapbox-gl.css";
// import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

// Stripe stuff - removing for now
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// const stripePromise = loadStripe(
//   process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
// );
// <Elements stripe={stripePromise}>
// </Elements>

import { ToastProvider } from "react-toast-notifications";
import { ThemeProvider } from "../contexts/ThemeContext/ThemeContext";
import { LanguageProvider } from "../contexts/LanguageContext/LanguageContext";
import Router from "next/router";
import { DefaultSeo } from "next-seo";
import SEO from "../next-seo.config";

//progress bar
import "nprogress/nprogress.css";

// calandar styling
import "../styles/calendar.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/common/main.css";
import "@fullcalendar/timeline/main.css";
import "@fullcalendar/resource-timeline/main.css";

import { init } from "commandbar";
import { getCurrentUserId } from "../lib/cookie";
import ErrorBoundary from "../components-reusable/Errors/ErrorBoundary/ErrorBoundary";
import { AnalyticsProvider } from "../contexts/Segment/Segment";
import RouterListener from "../components-reusable/App/RouterListener/RouterListener";

const MyApp = ({ Component, pageProps }) => {
  if (typeof window !== `undefined`) {
    const loggedInUserId = getCurrentUserId();
    if (loggedInUserId) {
      if (process.env.NEXT_PUBLIC_VERCEL_ENV === "development") {
        // init("08336f46");
        // window.CommandBar.boot(loggedInUserId);
        // window.CommandBar.addContext("userID", loggedInUserId);
        // const routerFunc = (newUrl) => Router.push(newUrl);
        // window.CommandBar.addRouter(routerFunc);
      }
    }
    window.__react_toast_provider = React.createRef();
  }

  return (
    <ToastProvider
      placement="bottom-left"
      autoDismiss
      ref={typeof window !== `undefined` && window.__react_toast_provider}
    >
      <LanguageProvider>
        <ThemeProvider>
          <AnalyticsProvider writeKey={process.env.NEXT_PUBLIC_SEGMENT_API_KEY}>
            <ErrorBoundary>
              <RouterListener>
                <DefaultSeo {...SEO} />
                <Component {...pageProps} />
              </RouterListener>
            </ErrorBoundary>
          </AnalyticsProvider>
        </ThemeProvider>
      </LanguageProvider>
    </ToastProvider>
  );
};

export default MyApp;
