import React, { useEffect } from "react";
import {
  fetchNewLaunguage,
  getLanguageFromLocalStorage,
  setLanguageInLocalStorage,
} from "../../helpers/languageHelper";

const getInitialLanguage = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedLang = getLanguageFromLocalStorage();
    if (storedLang) {
      return storedLang;
    }
  }

  return [];
};

export const LanguageContext = React.createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = React.useState(getInitialLanguage);

  useEffect(async () => {
    if (!getLanguageFromLocalStorage()) {
      const newLanguage = await fetchNewLaunguage();
      setLanguage(newLanguage);
      setLanguageInLocalStorage(newLanguage);
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
