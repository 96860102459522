import get from "lodash/get";

export function addToast(...args) {
  const add = get(window, "__react_toast_provider.current.add");

  if (!add) {
    console.error("Cannot find ref to toast function.");
    return;
  }

  add(...args);
}
