export default {
  title: "Tap For Service | The all-in-one software for Home Service Pros",
  description:
    "Tap For Service brings your scheduling, dispatching and invoicing into one delightful app that works anywhere.",
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://tapforservice.com/",
    site_name: "Tap For Service",
    images: [{ url: "https://tapforservice.com/images/og_image.jpg" }],
  },
  twitter: {
    cardType: "summary_large_image",
  },
};
