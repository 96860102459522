import { Component } from "react";
import * as Sentry from "@sentry/react";
import dynamic from "next/dynamic";
const FullErrorPage = dynamic(() => import("../FullErrorPage/FullErrorPage"));

class ErrorBoundary extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "_app.js error boundary");
        }}
        showDialog
        fallback={({ error, componentStack, resetError }) => (
          <FullErrorPage resetError={resetError} />
        )}
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;
