import API from "../lib/axios";

const LOCAL_STORAGE_NAME = "dashboard-language";

const fetchNewLaunguage = async () => {
  try {
    const response = await API.post("language/me", {});

    return response.data.result;
  } catch (error) {
    console.error("error", error);
  }
};

const resetLanguage = async () => {
  const newLanguage = await fetchNewLaunguage();
  setLanguageInLocalStorage(newLanguage);
  return;
};

const getLanguageFromLocalStorage = () => {
  let storedLang;

  if (typeof window !== "undefined" && window.localStorage) {
    storedLang = window.localStorage.getItem(LOCAL_STORAGE_NAME);
  }

  if (storedLang) {
    try {
      return JSON.parse(storedLang);
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
};

const setLanguageInLocalStorage = (langObject) =>
  window.localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(langObject));

const removeLanguageInLocalStorage = () =>
  window.localStorage.removeItem(LOCAL_STORAGE_NAME);

const getLanguageLabelFromKey = (
  language,
  searchKey,
  toLowerCase = false,
  plural = false
) => {
  // return empty string by default
  var result = "";

  if (!language) {
    language = getLanguageFromLocalStorage();
  }

  if (Array.isArray(language)) {
    language.forEach((key) => {
      if (key.name == searchKey && key.type == "TEXT") {
        result = key.label;
      } else if (key.name == searchKey && key.type == "SLCT") {
        result = key.options;
      } else if (key.name == searchKey && key.type == "BOOL") {
        result = key.value;
      }
    });
  }

  if (toLowerCase) result = result.toLowerCase();

  if (plural) {
    // eslint-disable-next-line no-undef
    var pluralize = require("pluralize");
    result = pluralize.plural(result);
  }

  return result;
};

export {
  fetchNewLaunguage,
  getLanguageFromLocalStorage,
  setLanguageInLocalStorage,
  getLanguageLabelFromKey,
  resetLanguage,
  removeLanguageInLocalStorage,
};
