import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { getActiveAuthToken } from "./cookie";
import { addToast } from "../utils/toast";

const instance = axios.create({
  baseURL: "https://taplaundry.com/api/v1",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});

instance.interceptors.request.use(
  async (config) => {
    if (typeof window !== "undefined" && getActiveAuthToken()) {
      config.headers.Authorization = `Bearer ${getActiveAuthToken().token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response?.data?.status === "error" && typeof window !== "undefined") {
      addToast(
        response?.data?.message ||
          "An unknown API error occured. Please contact our support team.",
        {
          appearance: "error",
          autoDismiss: false,
        }
      );
    } else if (response?.data?.status === "error") {
      console.error(response?.data);
    }

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
